import { Container, Grid, Heading, Text } from '@chakra-ui/react'
import React from 'react'

import { Layout } from '../components/layout'
import { SEO } from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Container maxW="4xl">
      <Grid gridGap="12">
        <Heading>Ooops...</Heading>
        <Text>The page you are looking for doesn't exist</Text>
      </Grid>
    </Container>
  </Layout>
)

export default NotFoundPage
